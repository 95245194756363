






































import Component from 'vue-class-component';
import Vue from 'vue';
import { Global, Props, User} from '@/store';
import PropUtils from '@/modules/PropUtils';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop() more_legend_icons;

  @Global.State('lang') lang;
  @Props.State('list') projectProperties;
  @User.State('project') project;

  mapLegend = false;
  cabinetCommunicationColors = null;
  fixtureCommunicationColors = null;
  communicationProperty = null;
  base_station_icon = require('@/assets/images/mapView_icons/antenna.svg');

  created(){
    this.communicationProperty = PropUtils.getProperty(this.projectProperties, 'dashboard.communication_monitoring');
    this.communicationProperty = this.communicationProperty ? this.communicationProperty.value : null;
    if (this.communicationProperty){
      this.cabinetCommunicationColors = this.communicationProperty['Cabinet Monitors'];
      this.fixtureCommunicationColors = this.communicationProperty.Fixtures;
    }else{
      console.log(`Couldnt find communication_monitoring property for this project`);
    }
  }

  get legendIcons(){
    if (this.communicationProperty){
      const levels = this.fixtureCommunicationColors.map((levelObject) => ({level_number: levelObject.level_number, color: levelObject.level})).sort((a, b) => a - b);
      const icons_per_level = levels.map((level, index) => {
        const url = [this.getColoredFixture(level.color.replace('#', '')), this.getColoredCabinet(level.color.replace('#', ''))];
        return index === 0 
          ? {text: 'Best Communication', url}
          : index === levels.length - 1 
          ? {text: 'Worst Communication', url}
          : {text: '-', url};
      });
      
      const more_icons = this.more_legend_icons.map((icon) => ({text: icon.text, url: [this.getColoredFixture(icon.color_code.replace('#', '')), this.getColoredCabinet(icon.color_code.replace('#', ''))]}));
      return icons_per_level.concat(more_icons);
    }
    return [];
  }

  getColoredFixture(color){
    return `data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' width='26' height='26' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23${color};%7D%0A%3C/style%3E%3Ccircle id='Oval' class='st0' cx='12' cy='12' r='10'/%3E%3C/svg%3E%0A`;
  }

  getColoredCabinet(color){
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" style='fill:%23${color}' width="24px" height="24px"%3E%3Cg%3E %3Crect fill="none" height="24" width="24"/%3E%3Cpath d="M14.5,11l-3,6v-4h-2l3-6v4H14.5z M7,1h10c1.1,0,2,0.9,2,2v18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V3C5,1.9,5.9,1,7,1z M7,6 v12h10V6H7z"/%3E%3C/g%3E%3C/svg%3E`;
  }
}
